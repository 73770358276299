
export default {
  inheritAttrs: false,

  props: {
    enabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    colorName() {
      return this.enabled ? "green" : "pink";
    },
  },
};
